import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Chatbot from "./components/Chatbot";
import Summarize from "./components/Summarize";
import MemoryDetails from "./components/MemoryDetails";
import Knowledge from "./components/Knowledge";

function App() {
  return (
    <Router>
      <div className="flex">
        {/* Sidebar is fixed */}
        <Sidebar />
        <div className="ml-64 p-6 w-full bg-gray-50">
          {" "}
          {/* Added "ml-64" to account for sidebar */}
          <Routes>
            {/* <Route path="/" element={<Chatbot />} />
            <Route path="/summarize" element={<Summarize />} />
            <Route path="/knowledge" element={<Knowledge />} />
            <Route path="/memory/:memory_id" element={<MemoryDetails />} /> */}
            <Route path="/" element={<Summarize />} />
            <Route path="/memory/:memory_id" element={<MemoryDetails />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
