import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMemoryDetails } from "../api/api";
import { FaUser, FaRobot } from "react-icons/fa";

const MemoryDetails = () => {
  const { memory_id } = useParams();
  const [memoryDetails, setMemoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchMemoryDetails = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await getMemoryDetails(memory_id);
        setMemoryDetails(response.data.data);
      } catch (error) {
        setError("Failed to fetch memory details");
      } finally {
        setLoading(false);
      }
    };

    fetchMemoryDetails();
  }, [memory_id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!memoryDetails) {
    return <p>No memory details found.</p>;
  }

  const apiResponse = memoryDetails.api_response;

  const renderApiResponse = () => {
    if (!apiResponse) {
      return <p>API response is missing or null.</p>;
    }

    switch (apiResponse.status_code) {
      case "BOT-000":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-002":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>Store Name:</strong> {apiResponse.store_name || "N/A"}
            </p>
            <p>
              <strong>Visit Time:</strong> {apiResponse.visit_time || "N/A"}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-003":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>Recall Time:</strong> {apiResponse.recall_time || "N/A"}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-004":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
          </div>
        );
      default:
        return <p>No valid status code found in API response.</p>;
    }
  };

  const formatBoldAndNewlineText = (text) => {
    const parts = text.split(/(\*\*[^*]+\*\*|\n)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else if (part === "\n") {
        return <br key={index} />;
      }
      return part;
    });
  };

  return (
    <div className="p-6 bg-white h-screen flex">
      <div className="w-1/2 pr-4">
        <h1 className="text-2xl font-bold mb-4">Memory Details</h1>
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Basic Information</h2>
          <p>
            <strong>Memory ID:</strong> {memoryDetails.memory_id}
          </p>
          <p>
            <strong>Laporan ID:</strong> {memoryDetails.id_laporan}
          </p>
          <p>
            <strong>Created At:</strong> {memoryDetails.created_at}
          </p>
          <p>
            <strong>Phone Number:</strong> {memoryDetails.phone_number}
          </p>
          <p>
            <strong>Summarize:</strong> <br /> <br />{" "}
            {formatBoldAndNewlineText(memoryDetails.summarize || "N/A")}
          </p>
        </div>
      </div>

      <div className="w-1/2 max-w-md mx-auto p-4 border rounded-lg shadow-lg bg-white flex flex-col h-full">
        <div className="flex-grow overflow-y-auto">
          {memoryDetails.conversations.map((conv, index) => (
            <div key={index}>
              {conv.user_message && (
                <div className="flex justify-end items-start space-x-2 mb-2">
                  <div className="bg-blue-500 text-white p-3 rounded-lg max-w-xs text-sm">
                    <p>{formatBoldAndNewlineText(conv.user_message)}</p>
                  </div>
                  <FaUser className="text-blue-500 text-lg" />
                </div>
              )}

              {conv.bot_response && (
                <div className="flex justify-start items-start space-x-2 mb-2">
                  <FaRobot className="text-gray-500 text-lg" />
                  <div className="bg-gray-200 p-3 rounded-lg max-w-xs text-sm">
                    <p>{formatBoldAndNewlineText(conv.bot_response)}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemoryDetails;
